import type { Route } from "vue-router";

import { Component, Vue } from "vue-property-decorator";
import { setUserInfo } from "@/lib/auth";

@Component
export default class GhostLogin extends Vue {
  private beforeRouteEnter(to: Route) {
    const { token, expires } = to.query;

    if (typeof token === "string" && Number.isInteger(+expires)) {
      setUserInfo({
        token,
        expires: +expires,
        isGhost: true,
      });
    }

    window.location.href = "/";
  }
}
